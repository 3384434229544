// main navbar
export const SIDEBAR_OPEN  = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'

export const PROFILE_NAVBAR_STATUS = 'PROFILE_NAVBAR_STATUS'

// theme
export const CHANGE_THEME = "CHANGE_THEME"

// property
export const SET_PROPERTY                = 'SET_PROPERTY'
export const PROPERTY_DELETE_MODAL_OPEN  = 'PROPERTY_DELETE_MODAL_OPEN'
export const PROPERTY_DELETE_MODAL_CLOSE = 'PROPERTY_DELETE_MODAL_CLOSE'
export const PROPERTY_CHANGES_STATUS     = 'PROPERTY_CHANGES_STATUS'

// deal
export const SET_DEAL                 = 'SET_DEAL'
export const DEAL_DELETE_MODAL_OPEN   = 'DEAL_DELETE_MODAL_OPEN'
export const DEAL_DELETE_MODAL_CLOSE  = 'DEAL_DELETE_MODAL_CLOSE'
export const DEAL_CHANGES_STATUS      = 'DEAL_CHANGES_STATUS'

// matches
export const SET_MATCH                = 'SET_MATCH'
export const MATCH_DELETE_MODAL_OPEN  = 'MATCH_DELETE_MODAL_OPEN'
export const MATCH_DELETE_MODAL_CLOSE = 'MATCH_DELETE_MODAL_CLOSE'
export const MATCH_CHANGES_STATUS     = 'MATCH_CHANGES_STATUS'

// post categories filter
export const SET_BLOG_CATEGORY = 'SET_BLOG_CATEGORY'

// agent inventory modal
export const AGENT_INVENTORY_MODAL_OPEN = 'AGENT_INVENTORY_MODAL_OPEN'
export const AGENT_INVENTORY_MODAL_CLOSE = 'AGENT_INVENTORY_MODAL_CLOSE'


// ================ admin ============
// post
export const SET_POST                = 'SET_POST'
export const POST_DELETE_MODAL_OPEN  = 'POST_DELETE_MODAL_OPEN'
export const POST_DELETE_MODAL_CLOSE = 'POST_DELETE_MODAL_CLOSE'
export const POST_CHANGES_STATUS     = 'PROPERTY_CHANGES_STATUSPOST_CHANGES_STATUS'
// user
export const SET_USER                = 'SET_USER'
export const USER_DELETE_MODAL_OPEN  = 'USER_DELETE_MODAL_OPEN'
export const USER_DELETE_MODAL_CLOSE = 'USER_DELETE_MODAL_CLOSE'
export const USER_CHANGES_STATUS     = 'USER_CHANGES_STATUS'
// form
export const SET_FORM                = 'SET_FORM'
export const FORM_DELETE_MODAL_OPEN  = 'FORM_DELETE_MODAL_OPEN'
export const FORM_DELETE_MODAL_CLOSE = 'FORM_DELETE_MODAL_CLOSE'
export const FORM_CHANGES_STATUS     = 'FORM_CHANGES_STATUS'
// offer
export const SET_OFFER                = 'SET_OFFER'
export const OFFER_DELETE_MODAL_OPEN  = 'OFFER_DELETE_MODAL_OPEN'
export const OFFER_DELETE_MODAL_CLOSE = 'OFFER_DELETE_MODAL_CLOSE'
export const OFFER_CHANGES_STATUS     = 'OFFER_CHANGES_STATUS'
// request
export const SET_REQUEST                = 'SET_REQUEST'
export const REQUEST_DELETE_MODAL_OPEN  = 'REQUEST_DELETE_MODAL_OPEN'
export const REQUEST_DELETE_MODAL_CLOSE = 'REQUEST_DELETE_MODAL_CLOSE'
export const REQUEST_CHANGES_STATUS     = 'REQUEST_CHANGES_STATUS'
// Agents Notification
export const SET_AGENTS_NOTIFICATION_DATA = 'SET_AGENTS_NOTIFICATION_DATA'
export const AGENTS_NOTIFICATION_MODAL_OPEN = 'AGENTS_NOTIFICATION_MODAL_OPEN'
export const AGENTS_NOTIFICATION_MODAL_CLOSE = 'AGENTS_NOTIFICATION_MODAL_CLOSE'
// ======= filter request ===========
// filter
export const SET_FILTER = 'SET_FILTER'
export const FILTER_MODAL_OPEN = 'FILTER_MODAL_OPEN'
export const FILTER_MODAL_CLOSE = 'FILTER_MODAL_CLOSE'
export const FILTER_CHANGES_STATE = 'FILTER_CHANGES_STATE'
// comment
export const SET_FILTER_COMMENT = 'SET_FILTER_COMMENT'
export const FILTER_COMMENT_MODAL_OPEN = 'FILTER_COMMENT_MODAL_OPEN'
export const FILTER_COMMENT_MODAL_CLOSE = 'FILTER_COMMENT_MODAL_CLOSE'
// status
export const SET_FILTER_CHNAGE_BASE_STATUS = 'SET_FILTER_CHNAGE_BASE_STATUS'
export const FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN = 'FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN'
export const FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE = 'FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE'
// holder
export const HOLDER_MODAL_OPEN = 'HOLDER_MODAL_OPEN'
export const HOLDER_MODAL_CLOSE = 'HOLDER_MODAL_CLOSE'
// ======= filter properties ===========
// filter
export const SET_FILTER_PROP = 'SET_FILTER_PROP'
export const FILTER_MODAL_OPEN_PROP = 'FILTER_MODAL_OPEN_PROP'
export const FILTER_MODAL_CLOSE_PROP = 'FILTER_MODAL_CLOSE_PROP'
export const FILTER_CHANGES_STATE_PROP = 'FILTER_CHANGES_STATE_PROP'
// comment
export const SET_FILTER_COMMENT_PROP = 'SET_FILTER_COMMENT_PROP'
export const FILTER_COMMENT_MODAL_OPEN_PROP = 'FILTER_COMMENT_MODAL_OPEN_PROP'
export const FILTER_COMMENT_MODAL_CLOSE_PROP = 'FILTER_COMMENT_MODAL_CLOSE_PROP'
// status
export const SET_FILTER_CHNAGE_BASE_STATUS_PROP = 'SET_FILTER_CHNAGE_BASE_STATUS_PROP'
export const FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_PROP = 'FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_PROP'
export const FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_PROP = 'FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_PROP'
// holder
export const HOLDER_PROP_MODAL_OPEN = 'HOLDER_PROP_MODAL_OPEN'
export const HOLDER_PROP_MODAL_CLOSE = 'HOLDER_PROP_MODAL_CLOSE'
// ======= filter agents ===========
// filter
export const SET_FILTER_AGETNS = 'SET_FILTER_AGETNS'
export const FILTER_MODAL_OPEN_AGETNS = 'FILTER_MODAL_OPEN_AGETNS'
export const FILTER_MODAL_CLOSE_AGETNS = 'FILTER_MODAL_CLOSE_AGETNS'
export const FILTER_CHANGES_STATE_AGETNS = 'FILTER_CHANGES_STATE_AGETNS'
// new agent
export const NEW_MODAL_OPEN_AGETNS = 'NEW_MODAL_OPEN_AGETNS'
export const NEW_MODAL_CLOSE_AGETNS = 'NEW_MODAL_CLOSE_AGETNS'
// comment
export const SET_FILTER_COMMENT_AGETNS = 'SET_FILTER_COMMENT_AGETNS'
export const FILTER_COMMENT_MODAL_OPEN_AGETNS = 'FILTER_COMMENT_MODAL_OPEN_AGETNS'
export const FILTER_COMMENT_MODAL_CLOSE_AGETNS = 'FILTER_COMMENT_MODAL_CLOSE_AGETNS'


// ======= filter inventories ===========
// filter
export const SET_FILTER_INVENTORY = 'SET_FILTER_INVENTORY'
export const FILTER_MODAL_OPEN_INVENTORY = 'FILTER_MODAL_OPEN_INVENTORY'
export const FILTER_MODAL_CLOSE_INVENTORY = 'FILTER_MODAL_CLOSE_INVENTORY'
export const FILTER_CHANGES_STATE_INVENTORY = 'FILTER_CHANGES_STATE_INVENTORY'
// comment
export const SET_FILTER_COMMENT_INVENTORY = 'SET_FILTER_COMMENT_INVENTORY'
export const FILTER_COMMENT_MODAL_OPEN_INVENTORY = 'FILTER_COMMENT_MODAL_OPEN_INVENTORY'
export const FILTER_COMMENT_MODAL_CLOSE_INVENTORY = 'FILTER_COMMENT_MODAL_CLOSE_INVENTORY'
// status
export const SET_FILTER_CHNAGE_BASE_STATUS_INVENTORY = 'SET_FILTER_CHNAGE_BASE_STATUS_INVENTORY'
export const FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_INVENTORY = 'FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_INVENTORY'
export const FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_INVENTORY = 'FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_INVENTORY'
// holder
export const HOLDER_INVENTORY_MODAL_OPEN = 'HOLDER_INVENTORY_MODAL_OPEN'
export const HOLDER_INVENTORY_MODAL_CLOSE = 'HOLDER_INVENTORY_MODAL_CLOSE'