import rent_icon_1 from "../assets/images/home/home_rent_icon1.svg"
import rent_icon_2 from "../assets/images/home/home_rent_icon22.svg"
import rent_icon_3 from "../assets/images/home/home_rent_icon3.svg"


import blog_1 from "../assets/images/blogs/home_blog_1.svg"
import blog_2 from "../assets/images/blogs/home_blog_2.svg"
import blog_3 from "../assets/images/blogs/home_blog_3.svg"

import {q_img0 , q_img1 , q_img2 , q_img3 , q_img4 , q_img5 , q_img6 , q_img7} from "../assets/images/forms"

export const api_url = process.env.GATSBY_Back_URL;
	// process.env.NODE_ENV === "production"
	// 	? process.env.REACT_APP_Back_URL_SERVER
	// 	: process.env.REACT_APP_Back_URL_TEST;

export const profile_url = process.env.GATSBY_Profile_URL;

export const matching_settings_access_users = process.env.GATSBY_MATCHING_SETTINGS_ACCESS_USERS;

export const navbarLinks = [
	{
		id: "navbarLinks_1",
		text: "Rent",
		href:"/rent/"
	},
	{
		id: "navbarLinks_2",
		text: "Switch",
		href:"/switch/"
	},
	{
		id: "navbarLinks_3",
		text: "Buy/Sell",
		href:"/buysell/"
	},
	{
		id: "navbarLinks_4",
		text: "Agents",
		href:"/agents/"
	},
	{
		id: "navbarLinks_5",
		text: "Landlords",
		href:"/landlords/"
	},
	{
		id: "navbarLinks_11",
		text: "Investor",
		href:"/investor/"
	},
	{
		id: "navbarLinks_6",
		text: "Corporate",
		href:"/corporate/"
	},
	{
		id: "navbarLinks_7",
		text: "Offers",
		href:"/offers/"
	},
	{
		id: "navbarLinks_8",
		text: "About Us",
		href:"/about/"
	},
	{
		id: "navbarLinks_9",
		text: "Contact Us",
		href:"/contact/"
	},
	{
		id: "navbarLinks_10",
		text: "Blog",
		href:"/blog/"
	},
];

export const rentSections = [
	{
		id:"rentSections_1",
		icon : rent_icon_1,
		text: "Spare 3 minutes to Share your Needs with us and Save weeks of Searching!"
	},
	{
		id:"rentSections_2",
		icon : rent_icon_2,
		text: "Get Matched with the Best Verified Properties through our Innovative Platform."
	},
	{
		id:"rentSections_3",
		icon : rent_icon_3,
		text: "Cut your Commute Time, Reduce your Carbon Footprint, and Enhance your Well-Being with us."
	},
]


export const blogs = [
	{
		id:"blogs_1",
		icon : blog_1,
		title: "10 Quick Tips About Real Estate",
		desc :"Lorem ipsum dolor sit amet consectetur. Praesent orci lectus mauris aliquet enim lectus quis. Tincidunt arcu",
		href: "/"
	},
	{
		id:"blogs_2",
		icon : blog_2,
		title: "10 Quick Tips About Real Estate",
		desc :"Lorem ipsum dolor sit amet consectetur. Praesent orci lectus mauris aliquet enim lectus quis. Tincidunt arcu",
		href: "/"
	},
	{
		id:"blogs_3",
		icon : blog_3,
		title: "10 Quick Tips About Real Estate",
		desc :"Lorem ipsum dolor sit amet consectetur. Praesent orci lectus mauris aliquet enim lectus quis. Tincidunt arcu",
		href: "/"
	},
]

export const questions_bg_imgs = [
	q_img0,q_img1 , q_img2 , q_img3 , q_img4 , q_img5 , q_img6 , q_img7
]

export const statisticsContent = [
	{
		id : "statisticsContent_1",
		title : "Properties",
		slides : [
			{
				id:"statisticsContent_1_slide_1",
				text: "Available",
				value : 100
			},
			{
				id:"statisticsContent_1_slide_2",
				text: "Not Available",
				value : 50
			},
		]
	},
	{
		id : "statisticsContent_2",
		title : "Properties Deals",
		slides : [
			{
				id:"statisticsContent_2_slide_1",
				text: "Closed",
				value : 50
			},
			{
				id:"statisticsContent_2_slide_2",
				text: "Open",
				value : 30
			}
		]
	},
	{
		id : "statisticsContent_3",
		title : "Leads Deals",
		slides : [
			{
				id:"statisticsContent_3_slide_1",
				text: "Closed",
				value : 60
			},
			{
				id:"statisticsContent_3_slide_2",
				text: "Open",
				value : 40
			}
		]
	},
]


export const propertiesType = [
	{
		id : 'propertiesType_1',
		title : "Villa",
		value : 1
	},
	// {
	// 	id : 'propertiesType_2',
	// 	title : "Compound Villa",
	// 	value : 2
	// },
	{
		id : 'propertiesType_4',
		title : "Townhouse",
		value : 2
	},
	{
		id : 'propertiesType_3',
		title : "Apartment",
		value : 3
	},
	// {
	// 	id : 'propertiesType_5',
	// 	title : "Land",
	// 	value : 5
	// },
	// {
	// 	id : 'propertiesType_6',
	// 	title : "Studio",
	// 	value : 6
	// },
]

export const propertiesSubType = [
	{
		id : 'propertiesSubType_1',
		title : "Gated Community",
		value : 1
	},
	{
		id : 'propertiesSubType_4',
		title : "Compound (Not gated)",
		value : 2
	},
	{
		id : 'propertiesSubType_3',
		title : "Independent",
		value : 3
	},
]

export const propertiesStyle = [
	{
		id : 'propertiesStyle_1',
		title : "FURNISHED",
	},
	{
		id : 'propertiesStyle_2',
		title : "UNFURNISHED",
	},
	{
		id : 'propertiesStyle_3',
		title : "SEMI-FURNISHED",
	}
]

export const propertiesSituation = [
	{
		id : 'propertiesSituation_1',
		title : "READY",
		value : "READY",
	},
	{
		id : 'propertiesSituation_2',
		title : "OFF PLAN",
		value : "OFF_PLAN",
	},
	// {
	// 	id : 'propertiesSituation_3',
	// 	title : "OFF PLAN SALE",
	// 	value : "OFF_PLAN_SALE",
	// },
]

export const propertiesOwnership = [
	{
		id : 'propertiesOwnership_1',
		title : "FREEHOLD",
		value : "FREEHOLD",
	},
	{
		id : 'propertiesOwnership_2',
		title : "LEASEHOLD",
		value : "LEASEHOLD",
	},
	{
		id : 'propertiesOwnership_3',
		title : "NON FREEHOLD",
		value : "NON FREEHOLD",
	},
]

export const propertiesFor = [
	{
		id : 'propertiesFor_1',
		title : "RENT",
		value : "RENT",
	},
	// {
	// 	id : 'propertiesFor_2',
	// 	title : "SWITCH",
	// 	value : "SWITCH",
	// },
	// {
	// 	id : 'propertiesFor_3',
	// 	title : "BUY",
	// 	value : "BUY",
	// },
	{
		id : 'propertiesFor_4',
		title : "SELL",
		value : "SELL",
	},
]

export const propertiesStatus = [
	// {
	// 	id : 'propertiesStatus_1',
	// 	title : "RENTED",
	// 	value : "RENTED",
	// },
	// {
	// 	id : 'propertiesStatus_2',
	// 	title : "SOLD",
	// 	value : "SOLD",
	// },
	// {
	// 	id : 'propertiesStatus_3',
	// 	title : "SWITCHED",
	// 	value : "SWITCHED",
	// },
	// {
	// 	id : 'propertiesStatus_4',
	// 	title : "VACANT",
	// 	value : "VACANT",
	// },
	// {
	// 	id : 'propertiesStatus_5',
	// 	title : "OCCUPIED",
	// 	value : "OCCUPIED",
	// },
	// {
	// 	id : 'propertiesStatus_6',
	// 	title : "VISITED",
	// 	value : "VISITED",
	// },
	{
		id : 'propertiesStatus_7',
		title : "CLOSED",
		value : "CLOSED",
	},
	{
		id : 'propertiesStatus_8',
		title : "AVAILABLE",
		value : "AVAILABLE",
	},
	{
		id : 'propertiesStatus_9',
		title : "FINALIZED",
		value : "FINALIZED",
	},
	{
		id : 'propertiesStatus_10',
		title : "ON_HOLD",
		value : "ON_HOLD",
	},
]

export const propertiesRooms = [
	{
		id : 'propertiesRooms_1',
		title : "Studio",
		value : "Studio",
	},
	{
		id : 'propertiesRooms_2',
		title : "1 Bedroom",
		value : "1",
	},
	{
		id : 'propertiesRooms_3',
		title : "2 Bedrooms",
		value : "2",
	},
	{
		id : 'propertiesRooms_4',
		title : "3 Bedrooms",
		value : "3",
	},
	{
		id : 'propertiesRooms_5',
		title : "4 Bedrooms",
		value : "4",
	},
	{
		id : 'propertiesRooms_6',
		title : "5 Bedrooms",
		value : "5",
	},
	{
		id : 'propertiesRooms_7',
		title : "6 Bedrooms",
		value : "6",
	},
	{
		id : 'propertiesRooms_8',
		title : "+7 Bedrooms",
		value : "7",
	},
	{
		id : 'propertiesRooms_9',
		title : "Penthouse",
		value : "Penthouse",
	},
	// {
	// 	id : 'propertiesRooms_10',
	// 	title : "Office",
	// 	value : "Office",
	// },
	// {
	// 	id : 'propertiesRooms_11',
	// 	title : "Shop",
	// 	value : "Shop",
	// },
]

export const propertiesConditions = [
	{
		id : 'propertiesConditions_1',
		title : "Well Maintained",
		value : "Well Maintained",
	},
	{
		id : 'propertiesConditions_2',
		title : "Requires Optional Renovations",
		value : "Requires Optional Renovations",
	},
	{
		id : 'propertiesConditions_3',
		title : "Fully Renovated",
		value : "Fully Renovated",
	},
	{
		id : 'propertiesConditions_4',
		title : "Brand New",
		value : "Brand New",
	}
]

export const DealType = [
	{
		id : "DealType_1",
		title : "Property",
	},
	{
		id : "DealType_2",
		title : "Lead",
	},
]

export const DealPropertyType = [
	{
		id : "DealPropertyType_1",
		title : "RENT",
		value : "RENT",
	},
	// {
	// 	id : "DealPropertyType_2",
	// 	title : "SWITCH",
	// 	value : "SWITCH",
	// },
	{
		id : "DealPropertyType_3",
		title : "BUY",
		value : "BUY",
	},
	// {
	// 	id : "DealPropertyType_4",
	// 	title : "SELL",
	// 	value : "SELL",
	// },
]

export const DealStatus = [
	{
		id : "DealStatus_1",
		title : "LEAD",
		value : 'LEAD',
	},
	{
		id : "DealStatus_2",
		title : "PROSPECT",
		value : 'PROSPECT',
	},
	{
		id : "DealStatus_3",
		title : "MATCHED",
		value : 'MATCHED',
	},
	{
		id : "DealStatus_4",
		title : "QUALIFIED",
		value : 'QUALIFIED',
	},
	{
		id : "DealStatus_5",
		title : "ENGAGED",
		value : 'ENGAGED',
	},
	{
		id : "DealStatus_6",
		title : "CLOSED",
		value : 'CLOSED',
	},
	{
		id : "DealStatus_7",
		title : "ON_HOLD",
		value : 'ON_HOLD',
	},
	{
		id : "DealStatus_8",
		title : "FINALIZED",
		value : 'FINALIZED',
	},
]

export const formsLinks = [
	{
		id :  "formsLinks_1",
		title : "Rent",
		ref   : "/rent/"
	},
	{
		id :  "formsLinks_2",
		title : "Switch",
		ref   : "/switch/"
	},
	{
		id :  "formsLinks_3",
		title : "Buy",
		ref   : "/buysell/buy/"
	},
	{
		id :  "formsLinks_4",
		title : "Sell",
		ref   : "/buysell/sell/"
	},
	{
		id :  "formsLinks_5",
		title : "Agents",
		ref   : "/agents/offers/"
	},
	{
		id :  "formsLinks_6",
		title : "landlords",
		ref   : "landlords/offers/"
	},
	// {
	// 	id :  "formsLinks_7",
	// 	title : "Corporate",
	// 	ref   : ""
	// },
]

export const formsLinksLandlordUser = [
	{
		id :  "formsLinks_1",
		title : "Rent",
		ref   : "/rent/offers",
		isLink : true
	},
	{
		id :  "formsLinks_2",
		title : "Switch",
		ref   : "/switch/offers",
		isLink : true
	},
	{
		id :  "formsLinks_3",
		title : "Buy",
		ref   : "/buysell/buy",
		isLink : true
	},
	{
		id :  "formsLinks_4",
		title : "Sell",
		ref   : "Sell",
		isLink : false
	},
	{
		id :  "formsLinks_5",
		title : "Rent Out",
		ref   : "Rent",
		isLink : false
	}
]
export const formsLinksAgent = [
	{
		id :  "formsLinks_1",
		title : "Customer Need",
		value: 'request'
	},
	{
		id :  "formsLinks_2",
		title : "Property",
		value: 'property'
	},
	{
		id :  "formsLinks_3",
		title : "Inventory",
		value: 'inventory'
	}
]

export const questionsDesc = [
	{
		id :  "propertyPrice",
		show : false,
		desc   : ""
	},
	{
		id :  "propertyRoom",
		show : true,
		desc   : "(Studio,1 bedroom, .... , +7)"
	},
	{
		id :  "propertyType",
		show : true,
		desc   : "(Villa,Townhouse,Apartments)"
	},
	{
		id :  "propertySubType",
		show : true,
		desc   : "(Gated,Compound,Independent)"
	},
	{
		id :  "propertyState",
		show : true,
		desc   : "(Well Maintained,Brand New,....)"
	},
	{
		id :  "propertyLocation",
		show : false,
		desc   : ""
	},
	{
		id :  "propertyMaidRoom",
		show : false,
		desc   : ""
	},
	{
		id :  "propertyStyle",
		show : true,
		desc   : "(Furnished,Unfurnished,Semi)"
	},
	{
		id :  "propertySwimmingPool",
		show : false,
		desc   : ""
	},
]

export const userTypes = [
	{
		id : 'userType_1',
		name : 'AGENT',
	},
	{
		id : 'userType_2',
		name : 'LANDLORD',
	},
	{
		id : 'userType_3',
		name : 'INDIVIDUAL',
	},
	{
		id : 'userType_4',
		name : 'ADMIN',
	},
]

export const propertyTypes = [
	{
		id : 'propertyTypes_1',
		name : 'Villa',
	},
	{
		id : 'propertyTypes_2',
		name : 'Apartment',
	},
	{
		id : 'propertyTypes_3',
		name : 'Townhouse',
	},
]


export const proposedOptionsStatus = [
	{
		id : 'proposedOptionsStatus_1',
		name : 'Pending',
	},
	// {
	// 	id : 'proposedOptionsStatus_2',
	// 	name : 'Accepted',
	// },
	// {
	// 	id : 'proposedOptionsStatus_3',
	// 	name : 'Refused',
	// },
	{
		id : 'proposedOptionsStatus_3',
		name : 'Rejected',
	},
]